//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ,
         getDictionary,
         getChosenState } from '~/utils/butterUtils'
import { STATE_ISO_CODE } from '~/utils/VFAParameters'

export default {
  head () {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription
        }
      ],
      link: [ 
        {
          rel: 'canonical',
          href: process.env.url+this.$route.path
        }
      ]
    }
  },
  data () {
    return {
      selectedState: null
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics.state_voting_rules : null
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    pageMetaDescription () {
      return this.dict.SEO_A21
    },
    pageTitle () {
      return process.env.siteTitle + " | " +  this.dict.SEO_A20
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    statesSortedLocalized () {
      return STATE_ISO_CODE
        .map(x => ({iso: x, name: this.getDictFLJ( `states.${x}`, this.dict)}))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
  },
  methods: {
      getDictFLJ(dictItem, dict) {
        /**
         * 2022-12-04 John Yee
         * This construction looks weird - like a recursive call; but, it's not.
         * The "getDictFLJ(dictItem, dict)" in the return statement is really
         * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
         * 
         * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
         */
        return getDictFLJ(dictItem, dict)
      },
  },
  mounted () {
    let chosenState = getChosenState(this.$store, this.$route)
    if (chosenState) {
      this.$router.push(this.localePath({ name: 'states-state', params: {state: chosenState}, query: {specialIssues: true} }))
    }
  },
}
